import request from './request'
import protocol from './protocol'
export default {

	app_name_range: [],

	app_channel_range: [],

	app_platform_range: [],

	_curAppData: null,

	app_list: [],

	get curAppId() {

		if (!this._curAppData) {
			let localData = localStorage.getItem('curAppData');
			this._curAppData = JSON.parse(localData);
		}

		return this._curAppData.id;
	},

	get curAppData() {
		return this._curAppData;
	},

	saveCurAppData(data) {
		this._curAppData = data;
		localStorage.setItem('curAppData', JSON.stringify(data));
	},

	async getSelectRange() {
		if (this.app_name_range.length <= 0) {
			await this.initAppRand();
		}

		return {
			app_name_range: this.app_name_range,
			app_channel_range: this.app_channel_range,
			app_platform_range: this.app_platform_range,
		}
	},

	async initAppRand() {
		let app_permission = localStorage.getItem('app_permission');
		if (!app_permission) {
			app_permission = 0;
		}
		let ret = await request.http.post(protocol.get_app_list, {
			app_permission: app_permission
		});
		if (ret) {
			this.app_list = ret;
			let localData = localStorage.getItem('curAppData');
			if (!localData) {
				this._curAppData = ret[0];
				localStorage.setItem('curAppData', JSON.stringify(this._curAppData));
			} else {
				this._curAppData = JSON.parse(localData);
			}

			this.app_name_range = [];
			this.app_channel_range = [];
			this.app_platform_range = [];

			let checkIsHave = (arr, value) => {
				return arr.some(v => {
					return v.lbl == value;
				});
			};


			for (let data of ret) {
				let app_name = data.app_name;
				let app_channel = data.app_channel;
				let app_platform = data.app_platform;

				if (!checkIsHave(this.app_name_range, app_name)) {
					this.app_name_range.push({
						lbl: app_name,
						id: this.app_name_range.length + 1
					});
				}

				if (app_name == this._curAppData.app_name && !checkIsHave(this.app_channel_range, app_channel)) {
					this.app_channel_range.push({
						lbl: app_channel,
						id: this.app_channel_range.length + 1
					});
				}

				if (app_name == this._curAppData.app_name && !checkIsHave(this.app_platform_range, app_platform)) {
					this.app_platform_range.push({
						lbl: app_platform,
						id: this.app_platform_range.length + 1
					});
				}
			}
		}
	},

	changeSelectRange(name) {

		console.log("选择游戏： ", name);

		let checkIsHave = (arr, value) => {
			return arr.some(v => {
				return v.lbl == value;
			});
		};

		let app_channel_range = [];
		let app_platform_range = [];
		for (let data of this.app_list) {
			let app_name = data.app_name;
			let app_channel = data.app_channel;
			let app_platform = data.app_platform;

			if (app_name == name && !checkIsHave(app_channel_range, app_channel)) {
				app_channel_range.push({
					lbl: app_channel,
					id: app_channel_range.length + 1
				});
			}

			if (app_name == name && !checkIsHave(app_platform_range, app_platform)) {
				app_platform_range.push({
					lbl: app_platform,
					id: app_platform_range.length + 1
				});
			}
		}

		return {
			app_channel_range,
			app_platform_range
		}
	}
}