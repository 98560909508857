export default {

	login: "manager/Login/login", //登录

	get_my_menu: "manager/Login/get_my_menu", //获取菜单

	count_info: "manager/Manager/count_info", //次留数据

	advert_report_count: "manager/Manager/advert_report_count", //广告次数

	report_everyday: "manager/Manager/report_everyday", //广告次数明细

	get_category_list: "manager/Manager/get_category_list", //一级分类(配表)数据

	get_category_list_two: "manager/Manager/get_category_list_two", //子级分类(配表)数据

	get_custom_list: "manager/Manager/get_custom_list", //自定义打点查询

	search_app: "manager/Manager/search_app", //查找应用渠道

	get_app_list: "manager/Manager/get_app_list", //应用列表

	get_app_id: "manager/Manager/get_app_id", //获取应用id

	seven_day_data: "manager/Manager/seven_day_data", //近7天数据

	actual_time_data: "manager/Manager/actual_time_data", //实时数据

	count_info_list: "manager/Manager/count_info_list", //次留数据列表

	get_new_task_report: "manager/Manager/get_new_task_report", //新手引导数据
}