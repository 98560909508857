<template>
	<div class="analysis">
		<sreachForm @sreach="showAdChart"></sreachForm>
		<div class="info">

			<el-card class="info_item" shadow="never">
				<div>总付费额</div>
				<div class="count">0.00</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="总付费额">
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					周同比<i class="el-icon-caret-top" /> 0％ 日环比<i class="el-icon-caret-bottom" />0％
				</div>
				<div class="dec">日均付费额 ¥ 0</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>支付笔数</div>
				<div class="count">0.00</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="支付笔数">
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u454.png" />
				</div>
				<div class="dec">付费人数 0</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>完播广告次数</div>
				<div class="count">0.00</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="完播广告次数">
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u464.png" />
				</div>
				<div class="dec">广告总收入 0 人均广告0次</div>
			</el-card>

			<el-card class="info_item" shadow="never">
				<div>广告完播率</div>
				<div class="count">0%</div>
				<div class="hit">
					<el-popover placement="top-start" title="说明" width="200" trigger="hover" content="广告完播率">
						<i class="el-icon-warning-outline" slot="reference"></i>
					</el-popover>
				</div>
				<div class="rate">
					<img src="../../../assets/u474.png" />
				</div>
				<div class="rate" style="border: unset;">
					周同比<i class="el-icon-caret-top" /> 0％ 日环比<i class="el-icon-caret-bottom" />0％
				</div>
			</el-card>


		</div>

		<div class="statistics">

			<el-tabs v-model="activeName">
				<el-tab-pane label="总付费量" name="1"></el-tab-pane>
				<el-tab-pane label="用户首日付费量" name="2"></el-tab-pane>
				<el-tab-pane label="2次付费用户" name="3"></el-tab-pane>
				<el-tab-pane label="3+次付费用户" name="4"></el-tab-pane>
				<el-tab-pane label="活跃付费用户" name="6"></el-tab-pane>
				<el-tab-pane label="arppu" name="7"></el-tab-pane>
			</el-tabs>

			<div class="statistics_date">
				<ul>
					<li>今日</li>
					<li>本周</li>
					<li>本月</li>
					<li>全年</li>
				</ul>
				<el-date-picker style="width: 256px;" v-model="date" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right"
					value-format="yyyy-MM-dd"></el-date-picker>
			</div>

			<div class="chart">
				<div id="rechargeChart" ref="rechargeChart"></div>
			</div>

		</div>

		<div class="statistics">

			<el-tabs v-model="activeName">
				<el-tab-pane label="广告播放量" name="1"></el-tab-pane>
				<el-tab-pane label="人均广告播放量" name="2"></el-tab-pane>
				<el-tab-pane label="ecpm" name="3"></el-tab-pane>
				<el-tab-pane label="广告收入" name="4"></el-tab-pane>
			</el-tabs>

			<div class="statistics_date">
				<ul>
					<li>今日</li>
					<li>本周</li>
					<li>本月</li>
					<li>全年</li>
				</ul>
				<el-date-picker style="width: 256px;" v-model="date" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right"
					value-format="yyyy-MM-dd"></el-date-picker>
			</div>

			<div class="chart">
				<div id="adChart" ref="adChart"></div>
			</div>

		</div>

		<div class="gear">
			<h3 class="title">付费档位</h3>
			<div class="chart">
				<div class="gearChart" id="gearChart1"></div>
				<div class="gearChart" id="gearChart2"></div>
				<div class="gearChart" id="gearChart3"></div>
			</div>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	import pancakeChart from '../../../js/echarts/pancakeChart.js'
	export default {
		name: 'analysis',
		data() {
			return {
				activeName: "1",
				date: "",
				pieData: [{
						name: '类别1',
						value: 30
					},
					{
						name: '类别2',
						value: 20
					},
					{
						name: '类别3',
						value: 50
					}
				]
			}
		},
		components: {
			sreachForm
		},
		mounted() {
			this.showRechargeChart();
			this.showAdChart();
			this.showGearChart();
		},

		methods: {

			showGearChart() {

				let titles = ['各档位付费金额比例（单位：rmb）', '各档位付费次数比例（单位：rmb）', '累计充值比例（单位：rmb）'];

				for (let i = 1; i <= titles.length; i++) {

					let dom = document.getElementById('gearChart' + i);
					if (!dom) {
						continue;
					}

					const pieChart = this.$echarts.init(dom);
					const option = pancakeChart.getChart(i - 1);
					pieChart.setOption(option);
				}
			},

			async showAdChart() {

				let ret = await this.$http.post(protocol.report_everyday, {
					"app_id": this.$userData.curAppId,
				});

				if (!this.$refs.adChart) {
					return;
				}

				const barChart = this.$echarts.init(this.$refs.adChart);
				const dates = [];
				const amounts = [];

				if (ret) {
					for (let key in ret) {
						dates.push(key);
						amounts.push(~~ret[key].complete_ad);
					}
				}

				const option = {
					title: {
						text: '广告趋势',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: dates
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						data: amounts,
						barMaxWidth: 30,
						barMinWidth: 30,
						type: 'bar'
					}]
				};

				barChart.setOption(option);
			},

			showRechargeChart() {
				if (!this.$refs.rechargeChart) {
					return;
				}
				const barChart = this.$echarts.init(this.$refs.rechargeChart);
				const dates = ['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04', '2024-01-05'];
				const amounts = [0, 0, 0, 0, 0];
				const option = {
					title: {
						text: '付费趋势',

					},
					xAxis: {
						type: 'category',
						data: dates
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 元'
						}
					},
					series: [{
						data: amounts,
						barMaxWidth: 30,
						barMinWidth: 30,
						type: 'bar'
					}]
				};
				barChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	.analysis {
		padding-bottom: 30px;

		.info {
			display: flex;
			align-items: center;

			.info_item {
				width: 266px;
				height: 188px;
				position: relative;
				margin-right: 30px;
				padding-bottom: 10px;

				.count {

					font-weight: bold;
					font-size: 30px;
					margin-top: 10px;
				}

				.rate {
					color: rgba(0, 0, 0, 0.6);
					margin-top: 10px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					padding-bottom: 20px;
					height: 25px;

					i {
						padding-left: 5px;
						padding-right: 5px;
					}

					i:nth-child(1) {
						color: rgba(82, 196, 26, 0.6);
					}

					i:nth-child(2) {
						color: #F5222D;
					}

					img {
						width: 218px;

					}
				}

				.hit {
					position: absolute;
					top: 10px;
					right: 10px;
				}

				.dec {
					color: rgba(0, 0, 0, 0.6);
					margin-top: 10px;
				}
			}
		}

		.statistics {
			margin-top: 40px;
			width: calc(~"100% - 100px");
			position: relative;

			.statistics_date {
				position: absolute;
				right: 0;
				top: -10px;
				display: flex;
				align-items: center;
			}

			ul {
				margin-right: 10px;
				display: flex;
				align-items: center;

				li {
					cursor: pointer;
					font-size: 13px;
					margin-left: 20px;
					color: rgba(0, 0, 0, 0.6);
				}
			}

			.chart {
				width: 1400px;

				#rechargeChart,
				#adChart {
					height: 400px;
				}
			}
		}

		.gear {
			width: calc(~"100% - 100px");

			.title {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding-bottom: 10px;
			}

			.gearChart {
				width: 700px;
				height: 500px;
				display: inline-block;
				margin-top: 30px;
			}
		}
	}
</style>