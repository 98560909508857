<template>
	<div class="dataHome">

		<sreachForm @sreach="selectChartType"></sreachForm>

		<div class="chart">
			<h4>总览趋势</h4>
			<el-radio-group v-model="select_tag" class="select_tag" @change="selectChartType">
				<el-radio-button label="1">激活数</el-radio-button>
				<el-radio-button label="2">创角数</el-radio-button>
			</el-radio-group>
			<div id="day_chart" ref="day_chart">
			</div>
		</div>

		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="日期">
				</el-table-column>
				<el-table-column prop="name" label="游戏">
				</el-table-column>
				<el-table-column prop="address" label="渠道">
				</el-table-column>
				<el-table-column prop="address" label="创角数">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="10"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>


	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	export default {
		name: 'dataHome',
		data() {
			return {
				select_tag: "1",
				chartData: {
					categories: [],
					data: []
				},
				tableData: [],
				cur_page: 1,
				lineChart: null,
				select_time: ""
			}
		},
		components: {
			sreachForm
		},
		mounted() {
			this.selectChartType();
		},

		methods: {

			async selectChartType(data) {
				if (data) {
					this.select_time = data.date;
				}
				let ret = await this.$http.post(protocol.count_info, {
					"app_id": this.$userData.curAppId,
					"select_time": this.select_time == "" ? [] : this.select_time
				});
				if (ret) {
					this.chartData.categories = [];
					this.chartData.data = [];
					for (let key in ret) {
						let curData = ret[key];

						let value = this.select_tag == "1" ? ~~curData["激活数"] : ~~curData["创角数"];

						this.chartData.categories.push(key);
						this.chartData.data.push(value);
					}
				}
				this.showDayChart();
			},

			showDayChart() {

				if (!this.$refs.day_chart) {
					return;
				}

				if (!this.lineChart) {
					this.lineChart = this.$echarts.init(this.$refs.day_chart);
				}

				const option = {
					title: {},
					tooltip: {},
					xAxis: {
						data: this.chartData.categories
					},
					yAxis: {},
					series: [{
						name: this.select_tag == "1" ? '激活数' : '创角数',
						type: 'line',
						data: this.chartData.data
					}]
				};

				this.lineChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	@import url('../../../common.less');

	.dataHome {

		.chart {

			.select_tag {
				margin-top: 30px;
			}

			#day_chart {
				height: 400px;
			}
		}

		.table {
			width: 90%;

			.pagination {
				text-align: center;
				margin-top: 30px;
			}
		}
	}
</style>