import request from "../request";
import protocol from "../protocol";


async function getSeverDayData(app_id) {

	var data = [];

	let ret = await request.http.post(protocol.seven_day_data, {
		"app_id": app_id,
	});

	if (ret) {
		for (let key in ret) {
			let curData = ret[key];
			data.push({
				date: key,
				totalActivations: ~~curData["激活数"],
				totalNewPlayers: ~~curData["创角数"],
				totalPayers: 0,
				totalRevenue: 0,
				avgAds: 0,
				cumulativeAds: 0,
				arpu: 0,
				retentionRate: 0
			});

			if (data.length == 7) {
				break;
			}
		}
	}

	var dates = data.map(item => item.date);
	var totalActivations = data.map(item => item.totalActivations);
	var totalNewPlayers = data.map(item => item.totalNewPlayers);
	var totalPayers = data.map(item => item.totalPayers);
	var totalRevenue = data.map(item => item.totalRevenue);
	var avgAds = data.map(item => item.avgAds);
	var cumulativeAds = data.map(item => item.cumulativeAds);
	var arpu = data.map(item => item.arpu);
	var retentionRate = data.map(item => item.retentionRate);

	var option = {

		tooltip: {
			trigger: 'axis'
		},
		legend: {
			data: ['总激活数', '总创角数', '充值总人数', '充值总金额', '人均广告', '当日累计广告', 'ARPU', '次日留存']
		},
		xAxis: {
			type: 'category',
			data: dates
		},
		yAxis: {
			type: 'value'
		},
		series: [{
				name: '总激活数',
				type: 'bar',
				data: totalActivations
			},
			{
				name: '总创角数',
				type: 'bar',
				data: totalNewPlayers
			},
			{
				name: '充值总人数',
				type: 'bar',
				data: totalPayers
			},
			{
				name: '充值总金额',
				type: 'bar',
				data: totalRevenue
			},
			{
				name: '人均广告',
				type: 'bar',
				data: avgAds
			},
			{
				name: '当日累计广告',
				type: 'bar',
				data: cumulativeAds
			},
			{
				name: 'ARPU',
				type: 'bar',
				data: arpu
			},
			{
				name: '次日留存',
				type: 'bar',
				data: retentionRate
			}
		]
	};

	return option;
};

export default {
	getSeverDayData
}