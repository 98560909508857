<template>
	<div class="sreachForm">
		<el-form :inline="true" ref="sreachForm" v-model="sreach_form">
			<el-form-item label="游戏:">
				<el-select v-model="sreach_form.app_name" placeholder="请选择游戏" @change="changeAppName">
					<el-option v-for="(item,index) in sreach_form.app_name_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="平台:">
				<el-select v-model="sreach_form.app_platform" placeholder="请选择平台">
					<el-option v-for="(item,index) in sreach_form.app_platform_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="渠道:">
				<el-select v-model="sreach_form.app_channel" placeholder="请选择渠道">
					<el-option v-for="(item,index) in sreach_form.app_channel_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区服:">
				<el-select v-model="sreach_form.server" placeholder="请选择区服">
					<el-option v-for="(item,index) in sreach_form.server_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-date-picker style="width: 256px;" v-model="sreach_form.date" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right"
					value-format="yyyy-MM-dd"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="clickSreach">查询</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: 'sreachForm',
		data() {
			return {
				sreach_form: {
					app_name: "",
					app_channel: "",
					app_platform: "",
					server: "",
					server_range: [],
					date: "",
					app_name_range: [],
					app_channel_range: [],
					app_platform_range: [],
				}
			}
		},

		mounted() {
			this.init();
		},

		methods: {

			async init() {
				let data = await this.$userData.getSelectRange();
				let curAppData = this.$userData.curAppData;
				if (!curAppData) {
					return;
				}

				this.sreach_form.app_name = curAppData.app_name;
				this.sreach_form.app_channel = curAppData.app_channel;
				this.sreach_form.app_platform = curAppData.app_platform;

				this.sreach_form.app_name_range = data.app_name_range;
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},

			changeAppName() {
				let app_name = this.sreach_form.app_name;
				let data = this.$userData.changeSelectRange(app_name);

				this.sreach_form.app_channel = "";
				this.sreach_form.app_platform = "";
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},

			clickSreach() {
				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.$userData.saveCurAppData(appData);
						this.$emit('sreach', {
							id: appData.id,
							date: this.sreach_form.date
						});
						return;
					}
				}

				this.$message({
					message: "未找到，对于游戏ID",
					type: 'error',
					duration: 1500
				});
			}
		}
	}
</script>

<style lang="less">
	.sreachForm {

		width: calc(~"100% - 100px");

		.el-select {
			width: 185px;
		}

		border-bottom: 1px solid rgba(0, 0, 0, .1);
		margin-bottom: 20px;
	}
</style>