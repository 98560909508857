function getChart(index) {

	var color = ["#00FFFC", "#FFC400", "#99D155", "#1AD979", "#3D99E8", "#FF8D3A"];

	let titles = ['各档位付费金额比例（单位：rmb）', '各档位付费次数比例（单位：rmb）', '累计充值比例（单位：rmb）'];

	let names = ['付费金额', '付费次数', '付费总额'];

	let datas = [{
			value: 40,
			name: '1',
		},
		{
			value: 24,
			name: '2'
		},
		{
			value: 32,
			name: '3'
		},
		{
			value: 32,
			name: '4'
		},
		{
			value: 32,
			name: '5'
		},
		{
			value: 32,
			name: '6'
		},
	];

	const rich = {
		title: {
			color: '#000000',
			fontSize: 17,
		},
		titleUnit: {
			color: '#000000',
			fontSize: 16,
			padding: [10, 0, 0, 0],
		},
		titleValue: {
			color: 'rgba(0, 0, 0, 1.0)',
			fontSize: 24,
			fontWeight: 700,
			padding: [15, 5, 5, 5],
		},
	};

	const title = {
		name: names[index],
		value: 0,
		unit: index == 0 ? '¥' : '',
	};

	let option = {
		color,
		title: {
			text: titles[index],
			x: 'center',
			y: 50
		},
		legend: {
			bottom: '42%',
			right: '10%',
			orient: 'vertical',
			data: datas,
			icon: 'square',
			itemWith: 10,
			itemHeight: 10,
			itemGap: 12,
			// padding: [5, 10, 5, 0],
			formatter(name) {
				const item = datas.filter((item) => item.name === name)[0];
				return `{name|${name}}{value1| ${item.value+'%}'}`;
			},
			textStyle: {
				rich: {
					name: {
						color: '#000000',
						fontSize: 14,
						width: 100,
					},
					value1: {
						color: '#000000',
						fontSize: 14,
						width: 66,
					},
				},
			},
		},
		series: [{
			name: '全网调控情况',
			type: 'pie',
			center: ['35%', '50%'],
			radius: ['40%', '50%'],
			clockwise: false, //饼图的扇区是否是顺时针排布
			avoidLabelOverlap: false,
			labelLine: {
				normal: {
					length: 15,
					length2: 13,
					smooth: true,
				}
			},
			label: {
				show: true,
				position: 'center',
				formatter: () =>
					`{title|${title.name}}\n{titleValue|${title.value}}{titleUnit|${title.unit}}`,
				rich,
			},
			data: datas
		}]
	};


	return option;
}

export default {
	getChart
}