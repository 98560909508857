<template>
	<div class="navView">

		<img src="../assets/Logo.png" />

		<el-menu class="nav_menu" mode="horizontal" :default-active="activeIndex" text-color="#030303"
			active-text-color="rgb(255, 208, 75)" background-color="rgba(255, 255, 255, 1.0)" :router="true">
			<el-menu-item index="/home">首页</el-menu-item>
			<el-menu-item index="/data">数据</el-menu-item>
			<el-menu-item index="/operate">运营</el-menu-item>
		</el-menu>
		<div class="content">
			<router-view />
		</div>

		<el-button class="btn_exit" @click="clickExit">退出</el-button>

	</div>
</template>

<script>
	import protocol from '../js/protocol.js'
	export default {
		name: 'navView',
		data() {
			return {
				activeIndex: "/home",
				homeRouters: ['/home', '/data', '/operate'],
			}
		},

		mounted() {
			this.init();
		},

		methods: {

			async init() {
				let path = this.$router.currentRoute.fullPath
				if ('/' == path) {
					let isLogin = localStorage.getItem('token') ? true : true;
					this.$router.push({
						path: isLogin ? '/home' : '/login'
					});
				} else {
					for (let router of this.homeRouters) {
						if (path.indexOf(router) >= 0) {
							this.activeIndex = router;
							break;
						}
					}
				}
			},

			clickExit() {
				this.$confirm('是否退出登录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: "/login"
					});
				}).catch(err => {

				});
			}
		}
	}
</script>

<style scoped lang="less">
	.navView {
		height: 100%;


		img {
			position: fixed;
			left: 10px;
			top: 10px;
			z-index: 100;
			width: 154px;
			height: 44px;
			border-radius: 10px;
		}

		.nav_menu {
			text-align: center;
			position: fixed;
			top: 0px;
			width: 100%;
			z-index: 99;
			box-shadow: 0 1px 5px 0 rgba(0, 21, 41, 0.12);

			.el-menu-item {
				margin-right: 60px;
				font-size: 16px;
			}

			.el-menu-item:nth-child(1) {
				margin-left: 270px;
			}


			:deep(.el-menu-item.is-active) {
				font-weight: bold !important;
			}

			:deep(.el-menu-item) {
				padding: 0px !important;
			}

			.el-menu-item:hover {
				background-color: unset !important;
			}
		}

		.content {
			height: calc(~"100% - 60px");
		}

		.btn_exit {
			position: fixed;
			top: 10px;
			right: 40px;
			z-index: 101;
		}
	}

	.el-menu-item:hover {
		background-color: #f9f9f9 !important;
	}

	:deep(.el-menu) {
		border-bottom: unset !important;
	}
</style>