<template>
	<div class="newUser">

		<sreachForm></sreachForm>

		<div class="chart">
			<el-button class="btn_export">导出</el-button>
			<div id="userRetentionChart" ref="userRetentionChart"></div>
		</div>

		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="日期">
				</el-table-column>
				<el-table-column prop="name" label="注册新增">
				</el-table-column>
				<el-table-column prop="address" label="新增人均打开次数">
				</el-table-column>
				<el-table-column prop="address" label="新增人均使用时长">
				</el-table-column>
				<el-table-column prop="address" label="新增人均广告次数">
				</el-table-column>
				<el-table-column prop="address" label="新增广告ecpm">
				</el-table-column>
				<el-table-column prop="address" label="新增广告流水">
				</el-table-column>
				<el-table-column prop="address" label="新增广告arpu">
				</el-table-column>
				<el-table-column prop="address" label="新增内购流水">
				</el-table-column>
				<el-table-column prop="address" label="首日内购arpu">
				</el-table-column>
				<el-table-column prop="address" label="首日内购率">
				</el-table-column>
				<el-table-column prop="address" label="新增ltv0">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="10"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>

		<sreachForm style="margin-top: 20px;"></sreachForm>

		<div class="chart">
			<el-button class="btn_export">导出</el-button>
			<el-radio-group v-model="select_tag" class="select_tag">
				<el-radio-button label="top">时</el-radio-button>
				<el-radio-button label="right">天</el-radio-button>
			</el-radio-group>
			<div id="newUserChart" ref="newUserChart"></div>
		</div>

		<div class="distribution">
			<el-radio-group v-model="select_tag" class="select_tag">
				<el-radio-button label="top">激活数</el-radio-button>
				<el-radio-button label="right">注册数</el-radio-button>
			</el-radio-group>
			<div id="distributionChart" ref="distributionChart"></div>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	export default {

		name: 'newUser',

		data() {
			return {
				tableData: [],
				cur_page: 1,
				select_tag: "top",
				comboData: {
					categories: ['周一', '周二', '周三', '周四', '周五'],
					lineData: [0, 0, 0, 0, 0],
					barData: [0, 0, 0, 0, 0]
				}
			}
		},

		components: {
			sreachForm
		},

		mounted() {
			this.showUserRetentionChart();
			this.showNewUserChart();
			this.showDistributionChart();
		},

		methods: {

			showDistributionChart() {

				if (!this.$refs.distributionChart) {
					return;
				}

				const pieChart = this.$echarts.init(this.$refs.distributionChart);
				const option = {

					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)'
					},
					legend: {
						orient: 'vertical',
						left: 10,
						data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
					},
					series: [{
						name: '访问来源',
						type: 'pie',
						radius: '55%',
						center: ['50%', '60%'],
						data: [{
								value: 335,
								name: '直接访问'
							},
							{
								value: 310,
								name: '邮件营销'
							},
							{
								value: 234,
								name: '联盟广告'
							},
							{
								value: 135,
								name: '视频广告'
							},
							{
								value: 1548,
								name: '搜索引擎'
							}
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				};
				pieChart.setOption(option);
			},

			showNewUserChart() {

				if (!this.$refs.newUserChart) {
					return;
				}

				const comboChart = this.$echarts.init(this.$refs.newUserChart);

				const option = {
					title: {
						text: '新增用户'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['激活数', '创角数']
					},
					xAxis: {
						type: 'category',
						data: this.comboData.categories
					},
					yAxis: [{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value} 件'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value} 元'
							}
						}
					],
					series: [{
							name: '激活数',
							type: 'bar',
							data: this.comboData.barData
						},
						{
							name: '创角数',
							type: 'line',
							yAxisIndex: 1,
							data: this.comboData.lineData
						}
					]
				};

				comboChart.setOption(option);
			},

			showUserRetentionChart() {

				if (!this.$refs.userRetentionChart) {
					return;
				}

				const comboChart = this.$echarts.init(this.$refs.userRetentionChart);

				const option = {
					title: {
						text: '用户留存'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['激活数', '创角数']
					},
					xAxis: {
						type: 'category',
						data: this.comboData.categories
					},
					yAxis: [{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value} 件'
							}
						},
						{
							type: 'value',
							name: '',
							min: 0,
							max: 400,
							interval: 100,
							axisLabel: {
								formatter: '{value} 元'
							}
						}
					],
					series: [{
							name: '激活数',
							type: 'bar',
							data: this.comboData.barData
						},
						{
							name: '创角数',
							type: 'line',
							yAxisIndex: 1,
							data: this.comboData.lineData
						}
					]
				};

				comboChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	.newUser {

		.chart {
			position: relative;

			.btn_export {
				position: absolute;
				right: 100px;
				top: 0px;
				background-color: rgba(0, 191, 191, 1);
				color: white;
				z-index: 10;
			}

			.select_tag {
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: 10;
			}

			#userRetentionChart {
				height: 400px;
			}

			#newUserChart {
				height: 400px;
			}
		}

		.distribution {
			margin-top: 20px;

			position: relative;

			.select_tag {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10;
			}

			#distributionChart {
				height: 400px;
			}
		}
	}
</style>