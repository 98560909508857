<template>
	<div class="currencyLog">

		<sreachForm></sreachForm>

		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="用户ID">
				</el-table-column>
				<el-table-column prop="name" label="角色ID">
				</el-table-column>
				<el-table-column prop="address" label="渠道">
				</el-table-column>
				<el-table-column prop="address" label="游戏">
				</el-table-column>
				<el-table-column prop="address" label="角色名">
				</el-table-column>
				<el-table-column prop="address" label="类型">
				</el-table-column>
				<el-table-column prop="address" label="货币">
				</el-table-column>
				<el-table-column prop="address" label="消耗途径">
				</el-table-column>
				<el-table-column prop="address" label="数量">
				</el-table-column>
				<el-table-column prop="address" label="库存">
				</el-table-column>
				<el-table-column prop="address" label="日期">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="10"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';

	export default {
		name: 'currencyLog',
		data() {
			return {
				sreach_form: {
					game: "",
					date: "",
					range: []
				},
				tableData: [],
				cur_page: 1
			}
		},

		components: {
			sreachForm
		},

		mounted() {

		},

		methods: {

		}
	}
</script>

<style lang="less">
	.currencyLog {
		.sreach_form {
			.el-select {
				width: 140px;
			}
		}
	}
</style>