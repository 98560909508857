<template>
	<div class="home">
		<el-form :inline="true" ref="sreachForm" v-model="sreach_form" class="sreachForm">
			<el-form-item label="游戏:">
				<el-select v-model="sreach_form.app_name" placeholder="请选择游戏" @change="changeAppName">
					<el-option v-for="(item,index) in sreach_form.app_name_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="平台:">
				<el-select v-model="sreach_form.app_platform" placeholder="请选择平台">
					<el-option v-for="(item,index) in sreach_form.app_platform_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="渠道:">
				<el-select v-model="sreach_form.app_channel" placeholder="请选择渠道">
					<el-option v-for="(item,index) in sreach_form.app_channel_range" :key="index" :label="item.lbl"
						:value="item.lbl"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="区服:">
				<el-select v-model="sreach_form.server" placeholder="请选择区服">
					<el-option v-for="(item,index) in sreach_form.server_range" :key="index" :label="item.lbl"
						:value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-date-picker style="width: 256px;" v-model="sreach_form.date" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right"
					value-format="yyyy-MM-dd"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="clickSreach">查询</el-button>
				<el-button>导出</el-button>
			</el-form-item>
		</el-form>

		<div class="real_time">
			<h3>实时数据</h3>
			<div id="real_time_chart" ref="real_time_chart"></div>
		</div>

		<div class="seven_day_data">
			<h3>近七天数据汇总</h3>
			<div id="seven_day_data_chart" ref="seven_day_data_chart"></div>
		</div>
	</div>
</template>

<script>
	import sevenDayChart from '../js/echarts/sevenDayChart.js'
	import protocol from '../js/protocol.js'
	export default {
		name: 'home',
		data() {
			return {
				sreach_form: {
					app_name: "",
					app_channel: "",
					app_platform: "",
					server: "",
					server_range: [],
					date: "",
					app_name_range: [],
					app_channel_range: [],
					app_platform_range: [],
				}
			}
		},

		mounted() {
			this.init();
		},

		methods: {

			async init() {
				let data = await this.$userData.getSelectRange();
				let curAppData = this.$userData.curAppData;
				if (!curAppData) {
					return;
				}

				this.sreach_form.app_name = curAppData.app_name;
				this.sreach_form.app_channel = curAppData.app_channel;
				this.sreach_form.app_platform = curAppData.app_platform;

				this.sreach_form.app_name_range = data.app_name_range;
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;

				this.realTimeChart();
				this.sevenDayDataChart();
			},

			clickSreach() {
				let app_name = this.sreach_form.app_name;
				let app_channel = this.sreach_form.app_channel;
				let app_platform = this.sreach_form.app_platform;

				for (let i = 0; i < this.$userData.app_list.length; i++) {
					let appData = this.$userData.app_list[i];
					if (app_name == appData.app_name && app_channel == appData.app_channel && app_platform == appData
						.app_platform) {
						this.$userData.saveCurAppData(appData);
						this.realTimeChart();
						this.sevenDayDataChart();
						return;
					}
				}

				this.$message({
					message: "未找到，对应游戏ID",
					type: 'error',
					duration: 1500
				});
			},

			changeAppName() {
				let app_name = this.sreach_form.app_name;
				let data = this.$userData.changeSelectRange(app_name);

				this.sreach_form.app_channel = "";
				this.sreach_form.app_platform = "";
				this.sreach_form.app_channel_range = data.app_channel_range;
				this.sreach_form.app_platform_range = data.app_platform_range;
			},

			async realTimeChart() {


				let res = {
					"app_id": this.$userData.curAppId
				};

				let ret = await this.$http.post(protocol.actual_time_data, res);
				let data = [0, 0, 0, 0, 0, 0, 0, 0];
				if (ret) {
					data[2] = ~~ret['激活数'];
					data[3] = ~~ret['创角数'];
				}

				if (!this.$refs.real_time_chart) {
					return;
				}

				var myChart = this.$echarts.init(this.$refs.real_time_chart);
				var option = {
					title: {},
					tooltip: {},
					legend: {},
					xAxis: {
						data: ["在线峰值", "当前在线", "总激活数", "总创角数", "今日活跃", "人均广告", "充值人数", "充值金额"]
					},
					yAxis: {},
					series: [{
						type: 'bar',
						data: data
					}]
				};

				myChart.setOption(option);
			},

			async sevenDayDataChart() {
				let option = await sevenDayChart.getSeverDayData(this.$userData.curAppId);
				if (!this.$refs.seven_day_data_chart) {
					return;
				}
				var myChart = this.$echarts.init(this.$refs.seven_day_data_chart);
				myChart.setOption(option);
			},
		}
	}
</script>

<style lang="less">
	.home {
		width: 85%;
		margin: auto;
		margin-top: 100px;

		.sreach_form {
			.el-select {
				width: 185px;
			}
		}

		.real_time {
			margin-top: 20px;

			#real_time_chart {
				width: 100%;
				height: 400px;
			}
		}

		.seven_day_data {

			margin-top: 20px;

			#seven_day_data_chart {
				height: 400px;
			}
		}
	}
</style>