<template>
	<div class="userRetention">
		<sreachForm @sreach="showContent"></sreachForm>
		<div class="chart">
			<el-radio-group v-model="select_tag" class="select_tag" @change="changeSelectDayCount">
				<el-radio-button label="1">次日</el-radio-button>
				<el-radio-button label="2">三日</el-radio-button>
				<el-radio-button label="3">7日</el-radio-button>
			</el-radio-group>
			<div id="retentionChart" ref="retentionChart"></div>
		</div>
		<div class="table">
			<el-table :data="tableData" height="250" border style="width: 100%">
				<el-table-column prop="date" label="日期">
				</el-table-column>
				<el-table-column prop="register" label="注册">
				</el-table-column>
				<el-table-column prop="towday" label="次日留存用户数">
				</el-table-column>
				<el-table-column prop="threeday" label="三日留存用户数">
				</el-table-column>
				<el-table-column prop="sevenday" label="七日留存用户数">
				</el-table-column>
				<el-table-column prop="towdayrate" label="次日留存率">
				</el-table-column>
				<el-table-column prop="threedayrate" label="三日留存率">
				</el-table-column>
				<el-table-column prop="sevendayrate" label="7日留存率">
				</el-table-column>
			</el-table>

			<el-pagination class="pagination" :current-page.sync="cur_page" :page-size="cur_size"
				layout="prev, pager, next, jumper" :total="0">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import sreachForm from '../../../components/sreachForm.vue';
	import protocol from '../../../js/protocol.js'
	export default {
		name: 'userRetention',

		components: {
			sreachForm
		},

		data() {
			return {
				select_tag: "1",
				cur_page: 1,
				cur_size: 4,
				total: 15,
				tableData: [],
				select_time: ""
			}
		},

		mounted() {
			this.showContent();
		},

		methods: {

			showContent(data) {
				if (data) {
					this.select_time = data.date;
				}

				this.loadList();
				this.showRetentionChart();
			},

			async loadList() {
				let res = {
					"app_id": this.$userData.curAppId,
					"cur_page": this.cur_page,
					"page_size": this.cur_size,
					"select_time": this.select_time == "" ? [] : this.select_time
				};
				let ret = await this.$http.post(protocol.count_info, res);
				if (ret) {

					let list = ret;
					this.tableData = [];
					for (let key in list) {
						let data = list[key];
						let value = {
							date: data["日期"],
							register: ~~data["创角数"],
							towday: ~~data["次日留存用户数"],
							threeday: ~~data["三日留存用户数"],
							sevenday: ~~data["七日留存用户数"],
							towdayrate: ~~data["次日留存率"],
							threedayrate: ~~data["三日留存率"],
							sevendayrate: ~~data["7日留存率"],
						};
						this.tableData.push(value);
					}
				}
			},

			changeSelectDayCount() {
				this.showRetentionChart();
			},

			async showRetentionChart() {

				let res = {
					"app_id": this.$userData.curAppId,
					"cur_page": this.cur_page,
					"page_size": this.cur_size,
					"select_time": this.select_time == "" ? [] : this.select_time
				};

				const days = [];
				const retentionRate = [];

				let ret = await this.$http.post(protocol.count_info, res);

				if (!this.$refs.retentionChart) {
					return;
				}

				const lineChart = this.$echarts.init(this.$refs.retentionChart);

				let keys = {
					"1": "次日留存用户数",
					"2": "三日留存用户数",
					"3": "七日留存用户数"
				};

				if (ret) {
					let list = ret
					for (let key in list) {
						let data = list[key];
						days.push(data['日期']);
						retentionRate.push(~~data[keys[this.select_tag]]);
					}
				}

				const option = {
					title: {
						text: '用户留存',
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						data: days
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value}'
						}
					},
					series: [{
						data: retentionRate,
						type: 'line',
						smooth: true,
						lineStyle: {
							color: 'blue'
						}
					}]
				};

				lineChart.setOption(option);
			}
		}
	}
</script>

<style lang="less">
	.userRetention {
		padding-bottom: 30px;

		.chart {
			.select_tag {
				margin: 0;
				margin-bottom: 30px;
			}

			#retentionChart {
				height: 500px;
			}
		}
	}
</style>