<template>
	<div class="login">
		<div class="left">
			<img src="../assets/Logo.png" />
			<img src="../assets/Icon.png" />
		</div>
		<div class="right">
			<div class="content">
				<h3>账户密码登录</h3>
				<el-input placeholder="请输入账号" v-model="code"></el-input>
				<el-input placeholder="请输入密码" v-model="pass_word" type="passWord"></el-input>
				<el-button @click="clickLogin">立即登录</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import protocol from '../js/protocol.js'

	export default {
		name: 'login',
		data() {
			return {
				code: "",
				pass_word: "",
			}
		},

		mounted() {

		},

		destroyed() {

		},

		methods: {

			async clickLogin() {

				let res = {
					user_name: this.code,
					password: this.pass_word
				};

				let ret = await this.$http.post(protocol.login, res);

				if (ret) {
					localStorage.setItem('app_permission', ret.app_permission);
					this.$http.setLoginToken(ret.token);

					await this.$userData.initAppRand();

					this.$router.push({
						path: "/home"
					});
				}
			}
		}
	}
</script>

<style lang="less">
	@import url('../common.less');

	.login {
		height: 100%;
		.flex_mid;

		.left,
		.right {
			width: 50%;
			height: 100%;
			position: relative;
		}

		.left {
			background: rgba(225, 228, 228, 0.5);

			img:nth-child(1) {
				position: absolute;
				width: 154px;
				height: 44px;
				top: 7px;
				left: 0px;
			}

			img:nth-child(2) {
				position: absolute;
				width: 696px;
				height: 806px;
				.ads_mid;
			}
		}

		.right {
			.content {
				.ads_mid;
				width: 330.24px;


				h3 {
					color: rgb(255, 161, 39);
					padding-bottom: 41px;
				}

				.el-input {
					height: 40px;
				}

				.el-input:nth-child(3) {
					margin-top: 20px;
				}

				.el-button {
					width: 100%;
					height: 40px;
					background: rgb(255, 165, 38);
					color: white;
					margin-top: 29px;
				}
			}
		}
	}
</style>